export default [
  {
    name: 'Acre',
    uid: 'AC'
  },
  {
    name: 'Alagoas',
    uid: 'AL'
  },
  {
    name: 'Amapá',
    uid: 'AP'
  },
  {
    name: 'Amazonas',
    uid: 'AM'
  },
  {
    name: 'Bahia',
    uid: 'BA'
  },
  {
    name: 'Ceará',
    uid: 'CE'
  },
  {
    name: 'Distrito Federal',
    uid: 'DF'
  },
  {
    name: 'Espírito Santo',
    uid: 'ES'
  },
  {
    name: 'Goiás',
    uid: 'GO'
  },
  {
    name: 'Maranhão',
    uid: 'MA'
  },
  {
    name: 'Mato Grosso',
    uid: 'MT'
  },
  {
    name: 'Mato Grosso do Sul',
    uid: 'MS'
  },
  {
    name: 'Minas Gerais',
    uid: 'MG'
  },
  {
    name: 'Pará',
    uid: 'PA'
  },
  {
    name: 'Paraíba',
    uid: 'PB'
  },
  {
    name: 'Paraná',
    uid: 'PR'
  },
  {
    name: 'Pernambuco',
    uid: 'PE'
  },
  {
    name: 'Piauí',
    uid: 'PI'
  },
  {
    name: 'Rio de Janeiro',
    uid: 'RJ'
  },
  {
    name: 'Rio Grande do Norte',
    uid: 'RN'
  },
  {
    name: 'Rio Grande do Sul',
    uid: 'RS'
  },
  {
    name: 'Rondônia',
    uid: 'RO'
  },
  {
    name: 'Roraima',
    uid: 'RR'
  },
  {
    name: 'Santa Catarina',
    uid: 'SC'
  },
  {
    name: 'São Paulo',
    uid: 'SP'
  },
  {
    name: 'Sergipe',
    uid: 'SE'
  },
  {
    name: 'Tocantins',
    uid: 'TO'
  }
]

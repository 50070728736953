
import { computed, defineComponent } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useCheckout } from '@/composables/checkout/useCheckout'
import { required, minLength, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import states from './states'

export default defineComponent({
  components: {
    BaseInput,
  },

  setup () {
    const { t } = useI18n()

    const {
      checkoutFormData,
      updateCheckoutFormData
    } = useCheckout()

    const zipCode = computed({
      get: () => checkoutFormData.value.zipCode,
      set: (value) => updateCheckoutFormData({zipCode: value})
    })

    const street = computed({
      get: () => checkoutFormData.value.street,
      set: (value) => updateCheckoutFormData({street: value})
    })

    const number = computed({
      get: () => checkoutFormData.value.number,
      set: (value) => updateCheckoutFormData({number: value})
    })

    const complement = computed({
      get: () => checkoutFormData.value.complement,
      set: (value) => updateCheckoutFormData({complement: value})
    })

    const neighborhood = computed({
      get: () => checkoutFormData.value.neighborhood,
      set: (value) => updateCheckoutFormData({neighborhood: value})
    })

    const city = computed({
      get: () => checkoutFormData.value.city,
      set: (value) => updateCheckoutFormData({city: value})
    })

    const state = computed({
      get: () => checkoutFormData.value.state,
      set: (value) => updateCheckoutFormData({state: value})
    })

    const rules = computed(() => {
      return {
        zipCode: {
          required: helpers.withMessage(t('validations.required'), required),
          minLength: helpers.withMessage(t('validations.zipCode'),minLength(9)
          )
        },
        street: {
          required: helpers.withMessage(t('validations.required'), required)
        },
        number: {},
        complement: {},
        neighborhood: {
          required: helpers.withMessage(t('validations.required'), required)
        },
        city: {
          required: helpers.withMessage(t('validations.required'), required)
        },
        state: {
          required: helpers.withMessage(t('validations.required'), required)
        },
      }
    })

    // @ts-ignore
    const v$ = useVuelidate(rules, {zipCode, street, neighborhood, city, state })

    const zipCodeErrorMessage = computed(() => v$.value.zipCode.$errors[0]?.$message as string || undefined)
    const streetErrorMessage = computed(() => v$.value.street.$errors[0]?.$message as string || undefined)
    const neighborhoodErrorMessage = computed(() => v$.value.neighborhood.$errors[0]?.$message as string || undefined)
    const cityErrorMessage = computed(() => v$.value.city.$errors[0]?.$message as string || undefined)
    const stateErrorMessage = computed(() => v$.value.state.$errors[0]?.$message as string || undefined)

    return {
      zipCode,
      street,
      number,
      complement,
      neighborhood,
      city,
      state,
      zipCodeErrorMessage,
      streetErrorMessage,
      neighborhoodErrorMessage,
      cityErrorMessage,
      stateErrorMessage,
      states
    }
  }
})
